import {
  BetaTag,
  CardlessCardContent,
  CardlessCardHeader,
  CopyButton,
  enqueueSnackbar,
  LabelledCell,
  LabelledCellDef,
  MaskFullStory,
  ProductPreviewContext,
  Sensitive,
  useFlags,
  useHasPermission,
} from '@insidedesk/tuxedo';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Divider,
  IconButton,
  inputClasses,
  List,
  Popover,
  popoverClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { ArAgeIndicator, ClaimActions, ClaimCategoryEditor } from '..';
import { ExternalLink, PhoneNumber, VaultAutoLogin } from '../..';
import {
  useCheckNumbers,
  usePatchClaimDetails,
  usePatchWorkflowState,
} from '../../../hooks';
import { ClaimDetails } from '../../../types';
import {
  formatAddress,
  formatCurrency,
  formatDate,
  formatFullName,
  formatInsurerName,
  formatTaxId,
  formatZipcode,
} from '../../../utils';
import DataExpiredPopup, {
  useDataExpired,
} from '../DataExpiredPopup/DataExpiredPopup';

const ErrorPopover = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.paper}:has( .${inputClasses.error})`]: {
    outline: `2px solid ${theme.palette.error.dark}`,
  },
}));

function ClaimCategories({ claimDetails }: { claimDetails: ClaimDetails }) {
  const { hasPermission } = useHasPermission();
  const { preview } = useContext(ProductPreviewContext);

  const workflowStateMutation = usePatchWorkflowState(`${claimDetails.id}`);
  const patchClaimDetailsMutation = usePatchClaimDetails(
    claimDetails.id.toString(),
  );
  const [popperEl, setPopperEl] = useState<Element | null>(null);
  const categories = useMemo(() => {
    const primaryCategory = claimDetails.categories.includes('medicaid')
      ? 'Medicaid'
      : 'Commercial';
    const secondaryCategory = claimDetails.categories.includes('ortho')
      ? 'Ortho'
      : undefined;
    return [primaryCategory, secondaryCategory].filter(
      (category) => category !== undefined,
    );
  }, [claimDetails.categories]);

  return (
    <>
      <Stack direction='row' alignItems='center' spacing={1}>
        <span aria-label='Categories'>{categories.join(' / ')}</span>
        {hasPermission('write:rcm') && (
          <IconButton
            aria-label='Edit Categories'
            color='inherit'
            onClick={(e) => setPopperEl(e.currentTarget)}
            disabled={preview}
          >
            <EditIcon sx={{ fontSize: 16 }} />
          </IconButton>
        )}
      </Stack>
      <ErrorPopover
        anchorEl={popperEl}
        open={popperEl !== null}
        onClose={() => setPopperEl(null)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <ClaimCategoryEditor
          claimDetails={claimDetails}
          onClose={() => setPopperEl(null)}
          onSubmit={(newCategories) => {
            patchClaimDetailsMutation.mutate(
              { categories: newCategories },
              {
                onError: () => {
                  enqueueSnackbar({
                    variant: 'error',
                    message: 'Error setting claim category, please try again',
                  });
                },
              },
            );

            /**
             * Due to the ortho discrimator for workflow states, we need to
             * reset the workflow state back to None when adding or removing the
             * ortho category.
             */
            const isOrtho = claimDetails.categories.includes('ortho');
            const addOrtho = !isOrtho && newCategories.includes('ortho');
            const removeOrtho = isOrtho && !newCategories.includes('ortho');
            if (
              (addOrtho || removeOrtho) &&
              claimDetails.ui_state?.workflow_state
            ) {
              workflowStateMutation.mutate(null);
            }

            setPopperEl(null);
          }}
        />
      </ErrorPopover>
    </>
  );
}

function SubHeader({ claimDetails }: { claimDetails: ClaimDetails }) {
  const flags = useFlags();
  const { preview } = useContext(ProductPreviewContext);

  const items = [
    {
      label: 'DoS',
      description: "Patient's Date of Service",
      value: formatDate(claimDetails.service_date_start),
      hideInPreview: true,
    },
    {
      label: 'DoB',
      description: "Patient's Date of Birth",
      value: formatDate(claimDetails.patient.birth_date),
      phi: true,
    },
    {
      label: 'Policy ID',
      description: "Patient's Policy ID Number",
      value: claimDetails.subscriber_insurance_id,
      phi: true,
    },
    {
      label: 'ZIP',
      description: "Patient's Zipcode",
      value: formatZipcode(claimDetails.patient.address?.zipcode ?? null),
      phi: true,
    },
  ];
  return (
    <Stack direction='row' spacing={2} justifyContent='flex-start'>
      {items.map(({ label, description, value, phi, hideInPreview }) => (
        <Typography key={label} variant='h4'>
          {label}:{' '}
          {flags.clickTextToCopyValue ? (
            <CopyButton
              label={description}
              text={value}
              color='inherit'
              disabled={preview}
            >
              <MaskFullStory>
                <Sensitive phi={phi} hideInPreview={hideInPreview}>
                  {value}
                </Sensitive>
              </MaskFullStory>
            </CopyButton>
          ) : (
            <>
              <MaskFullStory>
                <Sensitive phi={phi} hideInPreview={hideInPreview}>
                  {value}
                </Sensitive>
              </MaskFullStory>
              <CopyButton
                label={description}
                text={value}
                color='inherit'
                disabled={preview}
              />
            </>
          )}
        </Typography>
      ))}
    </Stack>
  );
}

export default function PMSInfoCard({
  claimDetails,
  offset = 0,
}: {
  claimDetails: ClaimDetails;
  offset?: number;
}) {
  const { hasPermission } = useHasPermission();
  const checkNumbersQuery = useCheckNumbers(claimDetails.id.toString());
  const dataExpiredContext = useDataExpired([checkNumbersQuery]);
  const flags = useFlags();

  const providerInfo: LabelledCellDef[] = useMemo(
    () => [
      {
        label: 'Tax ID #',
        value: formatTaxId(claimDetails.provider.tax_id),
        copyable: true,
        phi: true,
      },
      {
        label: 'Provider',
        value: formatFullName(
          claimDetails.provider.first_name,
          claimDetails.provider.last_name,
        ),
        copyable: true,
        phi: true,
      },
      {
        label: 'NPI #',
        value: claimDetails.provider.npi?.toString() ?? 'N/A',
        copyable: true,
        phi: true,
      },
      {
        label: 'Provider License #',
        value: claimDetails.provider.license_number ?? 'N/A',
        copyable: true,
        phi: true,
      },
    ],
    [claimDetails.provider],
  );
  const insuranceInfo: LabelledCellDef[] = useMemo(
    () => [
      {
        label: 'Insurer',
        value: (
          <ExternalLink
            href={claimDetails.mapped_collector?.portal_url ?? null}
          >
            {formatInsurerName(claimDetails.original_insurer_name)}
          </ExternalLink>
        ),
        hideInPreview: true,
      },
      {
        label: 'Insurance Phone #',
        value: (
          <PhoneNumber
            number={claimDetails.mapped_collector?.phone ?? null}
            fallback='Unavailable'
          />
        ),
        hideInPreview: true,
      },
      {
        label: 'Insurance Provider Portal',
        value: (
          <VaultAutoLogin
            claimId={claimDetails.id}
            portal={claimDetails.mapped_collector?.portal_url ?? null}
          />
        ),
      },
      {
        label: 'Payer ID',
        value: claimDetails.electronic_id ?? '-',
        hideInPreview: true,
      },
    ],
    [
      claimDetails.original_insurer_name,
      claimDetails.mapped_collector?.portal_url,
      claimDetails.mapped_collector?.phone,
      claimDetails.id,
      claimDetails.electronic_id,
    ],
  );
  const subscriberInfo: LabelledCellDef[] = useMemo(
    () => [
      {
        label: 'Policy Holder',
        description: "Subscriber's Name",
        value: formatFullName(
          claimDetails.subscriber.first_name,
          claimDetails.subscriber.last_name,
        ),
        copyable: true,
        phi: true,
      },
      {
        label: 'Policy ID',
        description: "Subscriber's Policy ID Number",
        value: claimDetails.subscriber_insurance_id,
        copyable: true,
        phi: true,
      },
      {
        label: 'Date of Birth',
        description: "Subscriber's Date of Birth",
        value: formatDate(claimDetails.subscriber.birth_date),
        copyable: true,
        phi: true,
      },
      {
        label: 'Zip Code',
        description: "Subscriber's Zipcode",
        value: formatZipcode(claimDetails.subscriber.address?.zipcode ?? null),
        copyable: true,
        phi: true,
      },
    ],
    [claimDetails.subscriber, claimDetails.subscriber_insurance_id],
  );
  const submissionInfo: LabelledCellDef[] = useMemo(
    () => [
      {
        label: 'Original Submission Date',
        value: formatDate(claimDetails.submission_date),
      },
      {
        label: 'Expected Amount',
        value: formatCurrency(claimDetails.total_amount_expected),
        hideInPreview: true,
      },
      {
        label: 'Submitted Amount',
        value: formatCurrency(claimDetails.total_charge_amount),
      },
      {
        label: 'Reference',
        value: claimDetails.reference,
        copyable: true,
        phi: true,
      },
    ],
    [
      claimDetails.reference,
      claimDetails.submission_date,
      claimDetails.total_amount_expected,
      claimDetails.total_charge_amount,
    ],
  );

  const paymentInfo: LabelledCellDef[] = useMemo(() => {
    const insurerPaid = formatCurrency(
      claimDetails.best_response.matches
        .map((match) => match.fee_paid)
        .filter((val): val is number => val !== null)
        .reduce((acc: number | null, val) => (acc ?? 0) + val, null),
      { hideZeros: !flags.displayPaidZeroValue },
    );

    const checkData = checkNumbersQuery.data ?? [];
    const useOrderedList = checkData.length > 1;

    const methods = checkData.map(({ identifier, method }) => (
      <li key={identifier}>{method ?? 'Unavailable'}</li>
    ));

    const identifiers = checkData.map(({ identifier }) => (
      <li key={identifier}>{identifier}</li>
    ));

    const paymentType = useOrderedList ? (
      <List component='ol' sx={{ p: 0, ml: 2, listStyle: 'number' }}>
        {methods}
      </List>
    ) : (
      <div>{checkData[0]?.method ?? 'Unavailable'}</div>
    );

    const paymentReference = useOrderedList ? (
      <List component='ol' sx={{ p: 0, ml: 2, listStyle: 'number' }}>
        {identifiers}
      </List>
    ) : (
      <div>{checkData[0]?.identifier ?? 'Unavailable'}</div>
    );

    return [
      { label: 'Insurer Paid', value: insurerPaid, hideInPreview: true },
      ...(flags.displayPaymentMethod
        ? [
            { label: 'Payment Type', value: paymentType, hideInPreview: true },
            { label: 'Payment Reference', value: paymentReference, phi: true },
          ]
        : [
            {
              label: 'Payment #',
              value: checkNumbersQuery.data?.length
                ? checkNumbersQuery.data.map(({ identifier }) => (
                    <div key={identifier}>{identifier}</div>
                  ))
                : 'N/A',
              phi: true,
            },
          ]),
    ];
  }, [
    checkNumbersQuery.data,
    claimDetails.best_response.matches,
    flags.displayPaidZeroValue,
    flags.displayPaymentMethod,
  ]);

  const postingInfo: LabelledCellDef[] = useMemo(
    () => [
      {
        label: 'Posted Date',
        value: formatDate(claimDetails.posted_date),
        hideInPreview: true,
      },
      {
        label: 'Posted',
        value: formatCurrency(claimDetails.amount_paid),
        hideInPreview: true,
      },
    ],
    [claimDetails.amount_paid, claimDetails.posted_date],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Provider Info.',
        cells: providerInfo,
      },
      {
        title: 'Insurance Info.',
        cells: insuranceInfo,
        tag: claimDetails?.mapped_collector?.beta ? <BetaTag /> : undefined,
      },
      { title: 'Subscriber Info.', cells: subscriberInfo },
      { title: 'Submission Info.', cells: submissionInfo },
      { title: 'Payment Info.', cells: paymentInfo },
      { title: 'Posting Info.', cells: postingInfo },
    ],
    [
      claimDetails?.mapped_collector?.beta,
      insuranceInfo,
      paymentInfo,
      postingInfo,
      providerInfo,
      submissionInfo,
      subscriberInfo,
    ],
  );

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: offset,
          zIndex: 2,
          backgroundColor: 'background.default',
        }}
      >
        <Box
          position='absolute'
          top={0}
          right={0}
          pr={4}
          style={{ transform: 'translateY(-100%)' }}
        >
          {hasPermission('read:rcm') && (
            <ClaimActions claimDetails={claimDetails} />
          )}
        </Box>
        <CardlessCardHeader
          variant='outlined'
          color='accent-primary'
          HeaderProps={{
            disableTypography: true,
            title: (
              <Typography variant='h2' sx={{ flexGrow: 1 }}>
                <ClaimCategories claimDetails={claimDetails} />
              </Typography>
            ),
            subheader: <SubHeader claimDetails={claimDetails} />,
          }}
        />
      </Box>
      <CardlessCardContent
        data-testid='pms-info-card'
        variant='outlined'
        color='accent-secondary'
        sx={{ position: 'relative' }}
      >
        <DataExpiredPopup context={dataExpiredContext} />
        <ArAgeIndicator claimDetails={claimDetails} />
        <Sensitive phi hideInPreview={false}>
          <Box
            sx={{
              '*': { paddingRight: 1 },
              '*:last-child': { paddingRight: 0 },
            }}
          >
            <Typography variant='h3' component='span'>
              {claimDetails.facility.name}
            </Typography>
            <Typography variant='h3' component='span'>
              -
            </Typography>
            <Typography variant='h5' component='span'>
              {formatAddress(claimDetails.facility.address)}
            </Typography>
          </Box>
        </Sensitive>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ flexWrap: 'wrap', gap: 1 }}
          mt={3}
        >
          {columns.map(({ title, cells, tag }) => (
            <Stack key={title} direction='column' spacing={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h6'>{title}</Typography>
                {tag}
              </Box>
              <Divider sx={{ mt: '0 !important' }} />
              {cells.map((cell) => {
                const key =
                  typeof cell.label === 'string'
                    ? cell.label
                    : cell.description;
                const LabelledCellComponent = (
                  <LabelledCell key={key} {...cell} />
                );

                return subscriberInfo.includes(cell) ? (
                  <MaskFullStory key={key}>
                    {LabelledCellComponent}
                  </MaskFullStory>
                ) : (
                  LabelledCellComponent
                );
              })}
            </Stack>
          ))}
          <div /> {/* Force spacing to the right */}
        </Stack>
      </CardlessCardContent>
    </>
  );
}
